<!-- <button mat-flat-button extended class="btn" (click)="onAddNewRow()">Add</button>
<button mat-flat-button color="primary" class="btn" (click)="onSave()" cdkFocusInitial>Save</button> -->

<table mat-table [dataSource]="sortedData" *ngIf="sortedData?.data?.length>0" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
    <!-- Position Column -->
    <ng-container matColumnDef="fminute">
        <th mat-header-cell *matHeaderCellDef> from minute </th>
        <td mat-cell *matCellDef="let element">
            <input type="text" maxlength="5" [(value)]="element.fminute" [ngClass]="{'invalid-input':!isValid(element,'fminute')}" style="width: 100% !important;" matInput class="form-control" placeholder="0" (change)="castToFloat($event,element,'fminute','int',2000)" (keypress)="allow_DigitsOnly($event)">
        </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="tminute">
        <th mat-header-cell *matHeaderCellDef> to minute </th>
        <td mat-cell *matCellDef="let element">
            <input type="text" maxlength="5" [(value)]="element.tminute" [ngClass]="{'invalid-input':!isValid(element,'tminute')}" style="width: 100% !important;" matInput class="form-control" placeholder="0" (change)="castToFloat($event,element,'tminute','int',2000)" (keypress)="allow_DigitsOnly($event,true)">
        </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="cappingvalue">
        <th mat-header-cell *matHeaderCellDef>value </th>
        <td mat-cell *matCellDef="let element">
            <input type="text" maxlength="25" [(value)]="element.cappingvalue" [ngClass]="{'invalid-input':!isValid(element,'cappingvalue')}" style="width: 100% !important;" matInput class="form-control" placeholder="0" (change)="castToFloat($event,element,'cappingvalue','float',2000)" (keypress)="allow_amuont($event)">
        </td>
    </ng-container>
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="onRemoveRow(element.id)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

