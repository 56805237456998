import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../../../services/client.service';
import { ReportService } from '../../../services/report.service';
import { ThemeService } from '../../../services/theme.service';
import { UserService } from '../../user/user.service';
import * as custom from '../../../Utility/CustomValidators.utilities'
import { IEmbedConfiguration, models, Embed, factories, service } from 'powerbi-client';
import { PowerBIReportEmbedComponent, PowerBIPaginatedReportEmbedComponent } from 'powerbi-client-angular';
import * as moment from 'moment';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-rdl-report',
  templateUrl: './rdl-report.component.html',
  styleUrls: ['./rdl-report.component.css']
})
export class RdlReportComponent implements OnInit {
  public reportSettingTimeer: any;
  public isDarkMode: boolean = false;
  public isApiStatus: boolean = false;
  public groupId: string = '';
  public reportId: string = '';
  public fileStatus: string = '';
  public lookupData: any = {};
  public reportUniqueId: string = '';
  filterData: any[] = [];
  // Track Report embedding status
  isEmbedded = false;
  showFilter: boolean = false;
  // Overall status message of embedding
  displayMessage = 'The report is bootstrapped. Click Embed Report button to set the access token.';

  // CSS Class to be passed to the wrapper
  reportClass = 'report-container';
  // Power BI service
  powerbi!: service.Service;
  // Flag which specify the type of embedding
  phasedEmbeddingFlag = false;

  // Get the token expiration from the access token
  public tokenExpiration: any;

  _isLoading: boolean = true;
  filters: any = {
    practiceIds: [],
    fiscalyear: "",
    serviceLocations: [],
    serviceProviderIds: []
  };
  reportSetting: any = {};
  // Pass the basic embed configurations to the wrapper to bootstrap the report on first load
  // Values for properties like embedUrl, accessToken and settings will be set on click of button
  reportConfig: any = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Aad,
    accessToken: undefined,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      commands: {
        parameterPanel: {
          enabled: false,
          expanded: false
        },
      },
      panes: {
        filters: {
          visible: false
        },
        pageNavigation: {
          visible: false
        }
      }

    },
    theme: {
      themeJson: {}
    }
  };
  public TheamParameter = [
  //  {
  //  "name": "Month",
  //  "value": this.filters.fiscalyear
  //},
  {
    "name": "Canvas",
    "value": "#00163f"
  },
  {
    "name": "BGText",
    "value": "#ffffff"
  },
  {
    "name": "BackGround",
    "value": "#194784"
  },
  {
    "name": "Alternate1",
    "value": "#415e91"
  },
  {
    "name": "Alternate2",
    "value": "#15b6a"
  }
  ];
  
  // Wrapper object to access report properties
  @ViewChild('powerbiControl') reportObj!: any;
  constructor(public httpService: ReportService,
    private userService: UserService,
    private reportService: ReportService,
    private themeService: ThemeService,
    private clientService: ClientService,
    route: ActivatedRoute,
    router: Router,
    private auth: AuthService,
  ) {
    router.events.subscribe((val: any) => {
      //this.groupId = route.snapshot.params["id"];
      if (val.snapshot && val.snapshot.params["groupid"]) {
        this.isEmbedded = false;
        this._isLoading = true;
        this.reportId = val.snapshot.params["reportid"];
        this.groupId = val.snapshot.params["groupid"];
        //this.filters = { practiceIds: [], selectors: [{ code: 'DOE', description: 'DOE', active: 1 }] };
        this.embedReport();
      }
    })
    this.groupId = route.snapshot.params["groupid"];
    this.reportId = route.snapshot.params["reportid"];
    this.isDarkMode != this.themeService.isDarkMode();
    this.reportSettingTimeer = setInterval(() => {
      if (this.isDarkMode != this.themeService.isDarkMode()) {
        this.isDarkMode = this.themeService.isDarkMode();
        this.UpdateReportTheme();
      }

    }, 100);

    //setInterval(() => this.checkTokenAndUpdate(), this.INTERVAL_TIME);

  }
  async ngOnInit() {
    this.getReportFilter();
    this.TheamParameter = [
      //  {
      //  "name": "Month",
      //  "value": this.filters.fiscalyear
      //},
      {
        "name": "Canvas",
        "value": "#ffffff"
      },
      {
        "name": "BGText",
        "value": "#000000"
      },
      {
        "name": "BackGround",
        "value": "#D9D9D9"
      },
      {
        "name": "Alternate1",
        "value": "#e6e6e6"
      },
      {
        "name": "Alternate2",
        "value": "#F5F5F5"
      }
    ];
    await this.getLookupData();
    this.setUserToken();
  }
  setUserToken() {

    this.userService.setUserToken().then(res => {
      this.httpService.accessToken = res.token;
      this.tokenExpiration = res.expireOn;
      this.reportSetting = JSON.parse(res.reportSetting)
      this.embedReport();
    });
  }
  UpdateReportTheme() {
    this.userService.setUserToken().then(res => {
      this.httpService.accessToken = res.token;
      this.tokenExpiration = res.expireOn;
      this.reportSetting = JSON.parse(res.reportSetting);
      const report = this.reportObj.powerbi.embeds[0];
      if (report) {
        report.resetTheme();
        report.setAccessToken(this.httpService.accessToken);
        report.applyTheme({ themeJson: this.reportSetting });
        //report.reload();

      }
    });
  }
  /**
   * Embeds report
   *
   * @returns Promise<void>
   */
  async embedReport(): Promise<void> {
    if (this.filters.practiceIds.length == 0)
      this.filters.practiceIds = [this.lookupData.clients[0]];
    if (this.filters.serviceLocations.length == 0)
      this.filters.serviceLocations = [this.lookupData.serviceLocation.filter((w: any) => { return w.clientid == this.filters.practiceIds[0].code })[0]];
    if (this.filters.serviceProviderIds.length == 0)
      this.filters.serviceProviderIds = [this.lookupData.serviceProvider.filter((w: any) => { return w.clientid == this.filters.practiceIds[0].code })[0]];
    if (this.filters.fiscalyear == "") {
      this.filters.fiscalyear = moment().subtract(1, 'M').startOf('month').format("YYYYMM");
    }
    let reportResponse = await this.httpService.getEmbedURL(this.groupId, this.reportId);

    let parameterValues = [{
      "name": "Month",
      "value": this.filters.fiscalyear
    },
     ...this.TheamParameter
    ];

    let practiceValues = this.filters.practiceIds.map((w: any, inx: number) => { return { name: "Practice", value: w.code } })
    let LocationsValues = this.filters.serviceLocations?.map((w: any, inx: number) => { return { name: "Service_Location", value: w.code } }) ?? []
    let ProviderValues2 = this.filters.serviceProviderIds?.map((w: any, inx: number) => { return { name: "Provider", value: w.code } }) ?? []

    this._isLoading = true;

    (practiceValues ?? []).map((w: any) => {
      parameterValues.push(w);
    });
    (ProviderValues2 ?? []).map((w: any) => {
      parameterValues.push(w);
    });
    (LocationsValues ?? []).map((w: any) => {
      parameterValues.push(w);
    });
    parameterValues.push({ name: "Username", value: this.auth.currentUser.username });
    this.reportConfig = {
      ...this.reportConfig,
      id: reportResponse.id,
      embedUrl: reportResponse.embedUrl,
      accessToken: this.httpService.accessToken,
      theme: { themeJson: this.reportSetting },
      filters: [],
      parameterValues: parameterValues
    };
    // Update the reportConfig to embed the PowerBI report

    this.isEmbedded = true;
    if (this.reportObj) {
      const report = this.reportObj.powerbi.embeds[0];
      if (report) {
        report.off("loaded");
        report.on('loaded', (event: any) => {
          this._isLoading = false;
          console.log("Report loaded successful");
        })
        // Triggers when a report is successfully embedded in UI
        report.off("rendered");
        report.on("rendered", function () {
          console.log("Report render successful");
        });
        // Clear any other error handler event
        report.off("error");

        // Below patch of code is for handling errors that occur during embedding
        report.on("error", function (event: any) {
          let errorMsg = event.detail;

          // Use errorMsg variable to log error in any destination of choice
          console.error(errorMsg);


        });
      }
      else {
        this._isLoading = false;
      }
    }
    else {
      this._isLoading = false;
    }


  }
  displayFilters() {
    if (this.filterData)
      return this.filterData?.findIndex((w: any) => { return w.reportId == this.reportId }) > -1;

    return true;
  }
  async printReport() {

    const report = this.reportObj.powerbi.embeds[0];
    if (report) {
      this._isLoading = true;
      let request = {
        format: 'PDF',
        settings: {
          includeHiddenPages: true
        },
        paginatedReportConfiguration: {
          formatSettings: {
            "AccessiblePDF": false,
            "PageHeight": "17in",
            "PageWidth": "13in"
          },
          "parameterValues": report.config.parameterValues
        },
      }
      this.reportService.printReport(request, this.reportId).then(res => {
        console.log(res[0]);
        let exportId = res.id;
        console.log(exportId);
        this.checkReportStatus(request, exportId);
      }).catch(error => {
        this._isLoading = false;
        console.log(error);
        if (error?.error?.error?.message)
          alert(error.error.error.message);
      });
    }
  }

  checkReportStatus(request: any, exportId: string) {
    this.reportService.checkReportStatus(request, this.reportId, exportId).then(async resStaus => {
      this.fileStatus = await resStaus.status;
      if (this.fileStatus == "Running" || this.fileStatus == "NotStarted") {
        setTimeout((s: any) => {
          this.checkReportStatus(request, exportId);
        }, 5000)

      }
      else if (this.fileStatus == "Succeeded") {
        this.downloadReport(request, exportId, resStaus.reportName + resStaus.resourceFileExtension);
      }
      else if (this.fileStatus == "Failed") {
        this._isLoading = false;
      }

    });
  }
  downloadReport(request: any, exportId: string, filename: string) {
    this._isLoading = false;
    this.reportService.downloadReport(request, this.reportId, exportId, filename, this.aftredownloadReport);
  }
  aftredownloadReport() {

  }
  async getLookupData() {

    this._isLoading = true;
    const locationRequest = [{
      clientid: '',
      uniqueservicelocationid: -1,
      pagesize: 10000,
      pagestartrecord: 0,
    }]
    const providerRequest = [{
      clientid: '',
      uniqueservicingproviderid: -1,
      pagesize: 10000,
      pagestartrecord: 0,
    }]
    const lookups = await Promise.all([
      this.clientService.getlookup([]),
      this.clientService.getServiceLocations(locationRequest),
      this.clientService.getServicingProviders(providerRequest)
    ]);

    this.lookupData = {
      clients: lookups[0]?.map((s: any) => { return { code: s.clientid, description: s.clientname, active: true } }),
      serviceLocation: lookups[1]?.filter((s: any) => { return s.servicelocationname ?? s.description })?.map((s: any) => { return { code: s.uniqueservicelocationid ?? s.code, description: s.servicelocationname ?? s.description, active: true, clientid: s.clientid } }),
      serviceProvider: lookups[2]?.filter((s: any) => { return s.servicingprovidername ?? s.description })?.map((s: any) => { return { code: s.uniqueservicingproviderid ?? s.code, description: s.servicingprovidername ?? s.description, active: true, clientid: s.clientid } }),

      payorGroups: [],
      payors: [],
    }
    this.lookupData.serviceLocation.push({
      "code": "3_202311086767_1",
      "description": "3_202311086767_1",
      "active": true,
      "clientid": "3_202311086767"
    },)
    this.lookupData.clients = (this.lookupData.clients ?? []).sort((a: any, b: any) => {
      return custom.compare(a.description?.toLocaleLowerCase() as string, b.description.toLocaleLowerCase() as string, true);
    });
    this.lookupData.serviceLocation = (this.lookupData.serviceLocation ?? []).sort((a: any, b: any) => {
      return custom.compare(a.description?.toLocaleLowerCase() as string, b.description.toLocaleLowerCase() as string, true);
    });
    this.lookupData.serviceProvider = (this.lookupData.serviceProvider ?? []).sort((a: any, b: any) => {
      return custom.compare(a.description?.toLocaleLowerCase() as string, b.description.toLocaleLowerCase() as string, true);
    });

  }
  async doFilter(items: any) {
    if (items) {
      this.filters = items;
    }

    //this.reportObj.powerbi.embeds[0].config.parameterValues = this.filters.practiceIds.map((w:any) => { return { name: "Practice_ID", value: w.code } })
    this.showFilter = false;
    //await this.reportObj._embed.reload()
    //this.reportConfig = {
    //  ...this.reportConfig,

    //  parameterValues: this.filters.practiceIds.map((w: any) => { return { name: "Practice_ID", value: w.code } })
    //};
    this.isEmbedded = false;
    // this.reportObj.powerbi.reset();
    this.embedReport();

    //let report = await this.reportObj.powerbi.embed(this.reportObj.containerRef.nativeElement, this.reportConfig);
    //await this.reportObj.powerbi.embeds[0].refresh();
    // let s = await this.reportObj.powerbi.embeds[0].getFilters();
    //console.log(s);
    //report.setFilters([]);
    //this.embedReport();
  }
  getReportFilter() {
    const request = [{
      id: -1,
      statusid: -1,
      pagesize: 500,
      pagestartrecord: 0
    }]
    this.clientService.getreportfilter(request).then(res => {
      this.filterData = res;
    });

  }
}
