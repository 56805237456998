<div class="body-wrapper">
  <mat-card class="main-card">
    <mat-card-content>
      <mat-card-title>
        Physician Period
        <button *ngIf="!showEdit" mat-mini-fab color="primary" style="float:right" (click)="onAddPhysician()">
          <mat-icon>add</mat-icon>
        </button>

        <div class="col-lg-1" style="cursor: pointer; display: flow-root; float: right; height: fit-content; width: fit-content;" *ngIf="!showEdit">
          <input #f_input type="file" id="upload" class="account-file-input" hidden="" accept=".csv,.xls,.xlsx" (change)="onChange($event)">
          <!--<button mat-flat-button style="margin-right: 10px; margin-bottom: 10px;" (click)="f_input.click()" color="primary">Upload</button>-->
          <p mat-mini-fab class="material-icons material-icon-preview" (click)="f_input.click()" color="primary" style="background: var(--background-color) !important; background-color: var(--background-color) !important; border-color: var(--background-color) !important; color: #fff; border-radius: 20px; font-size: 1.54em; margin-bottom: 0px; margin-right: 9px; padding: 3px; font-size: 32px; margin-top: 2px;">file_upload</p>
        </div>

        <div class="form-group col-lg-2" style="float: right; margin-right: 20px;" *ngIf="!showEdit">
          <!--<div class="form-group col-lg-2" style="float: right;" *ngIf="!showEdit">-->
            <input matInput class="form-control" (keyup)="applyFilter($event)" placeholder="Search" style="margin-left: -28px; font-size: small;" [value]="filtervalue">
          </div>
      </mat-card-title>
      <mat-card-subtitle> Home &gt; Physician Period</mat-card-subtitle>
      <mat-divider></mat-divider>      
      <app-physician-period-list [physicianList]="physicianList" *ngIf="!showEdit" [filtervalue]="filtervalue" (onEditPhysician)="onEditPhysician($event)" (onDelete)="doDelete($event)" (onShowDetails)="onShowDetails($event)"></app-physician-period-list>      
      <section class="edit-container" *ngIf="showEdit">
       <app-physician-period-edit  [lookupData]="lookupData" [isLoading]="isLoading" [physician]="physician" (onCancelEdit)="onCancelEdit($event)" (onShowLoading)="onShowLoading($event)" (onUpdateEdit)="doUpdateEdit($event)"></app-physician-period-edit>
      </section>
    </mat-card-content>
  </mat-card>
</div>
<app-data-loader [isLoading]="isLoading"></app-data-loader>

