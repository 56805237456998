<div class="body-wrapper">
    <mat-card class="main-card">
        <mat-card-content>
            <mat-card-title>
                Net Revenue
                <button *ngIf="!showEdit" mat-mini-fab color="primary" style="float:right" (click)="onAddNetRevenue()">
                    <mat-icon>add</mat-icon>
                </button>

                <div class="col-lg-1" style="cursor: pointer; display: flow-root; float: right; height: fit-content; width: fit-content;" *ngIf="false">
                    <input #f_input type="file" id="upload" class="account-file-input" hidden="" accept=".csv,.xls,.xlsx" (change)="onChange($event)">
                    <!--<button mat-flat-button style="margin-right: 10px; margin-bottom: 10px;" (click)="f_input.click()" color="primary">Upload</button>-->
                    <p mat-mini-fab class="material-icons material-icon-preview" (click)="f_input.click()" color="primary"
                    style="background: var(--background-color) !important; background-color: var(--background-color) !important;  border-color: var(--background-color) !important; color: #fff; border-radius: 20px; font-size: 1.54em; margin-bottom: 0px; margin-right: 9px; padding: 3px; font-size: 32px; margin-top: 2px;">file_upload</p>
                </div>

                <div class="form-group col-lg-2" style="float: right; margin-right: 20px;" *ngIf="!showEdit">
                    <!--<div class="form-group col-lg-2" style="float: right;" *ngIf="!showEdit">-->
                    <input matInput class="form-control" (keyup)="applyFilter($event)" placeholder="Search" style="margin-left: -28px; font-size: small;" [value]="filtervalue">
                </div>
            </mat-card-title>
            <mat-card-subtitle> Home &gt; Net Revenue</mat-card-subtitle>
            <mat-divider></mat-divider>
            <app-net-revenue-list [netRevenueList]="netRevenueList" *ngIf="!showEdit" [filtervalue]="filtervalue" (onEditNetRevenue)="onEditNetRevenue($event)" (onDelete)="doUpdateEdit($event)" (onShowDetails)="onShowDetails($event)"></app-net-revenue-list>
            <section class="edit-container" *ngIf="showEdit" style="overflow-x: hidden;">
                <app-net-revenue-edit [lookupData]="lookupData" [isLoading]="isLoading" [netRevenue]="netRevenue" (onCancelEdit)="onCancelEdit($event)" (onShowLoading)="onShowLoading($event)" (onUpdateEdit)="doUpdateEdit($event)"></app-net-revenue-edit>
            </section>
        </mat-card-content>
    </mat-card>
</div>
<app-data-loader [isLoading]="isLoading"></app-data-loader>

