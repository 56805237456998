import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Console } from 'console';

@Component({
  selector: 'app-capping-list',
  templateUrl: './capping-list.component.html',
  styleUrls: ['./capping-list.component.css']
})
export class CappingListComponent implements OnInit {
  public ELEMENT_DATA: any[] = [
    //{ fminute: 1, tminute: 10, cappingvalue: 1.79 },
    //{ fminute: 11, tminute: -1, cappingvalue: 5.00 },
  ];
  displayedColumns: string[] = ['fminute', 'tminute', 'cappingvalue','action'];
  @Input() set values(value: string) {
    this.ELEMENT_DATA = [];
   //debugger;
    if (value??"" != "") {
      let i = 0
      for (i = 0; i < value.split('||').length; i++) {
        let val = value.split('||')[i];
        this.ELEMENT_DATA.push({
          fminute: val.split('|')[0],
          tminute: val.split('|')[1],
          cappingvalue: val.split('|')[2]
        })
      }
      //this.onTimeCappingChanges.emit();
    }
    this.sortedData.data = this.ELEMENT_DATA;
  }
  @Output() onTimeCappingChanges = new EventEmitter();
  get values() {
    return this.ELEMENT_DATA.map(w => { return w.fminute + '|' + w.tminute + '|' + w.cappingvalue }).join('||');
  }
  get isValidData() {
    let vlid = true;

    for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
      if (!this.isValid(this.ELEMENT_DATA[i], 'fminute') ||
        !this.isValid(this.ELEMENT_DATA[i], 'tminute') ||
        !this.isValid(this.ELEMENT_DATA[i], 'cappingvalue')
      ) {
        vlid = false;
      }
      else if (i == 0 && parseInt(this.ELEMENT_DATA[0].fminute) != 0)
      {
        vlid = false;
      }
      else if (i > 0 && parseInt(this.ELEMENT_DATA[i - 1].tminute) + 1 != parseInt(this.ELEMENT_DATA[i].fminute)) {
        vlid = false;
      }
      else if (i == this.ELEMENT_DATA.length - 1 && parseInt(this.ELEMENT_DATA[this.ELEMENT_DATA.length - 1].tminute) != -1) {
        vlid = false;
      }
      else {
        console.log("aad");
      }
    }
     

    return vlid;
  }
  public sortedData = new MatTableDataSource<any>([]);
  ngOnInit() {
    /*
    let value = "|10|5||11|-1|10";
    for (let i = 0; i < value.split('||').length; i++) {
      let val = value.split('||')[i];
      this.ELEMENT_DATA.push({
        id: i + 1,
        fminute: val.split('|')[0],
        tminute: val.split('|')[1],
        cappingvalue: val.split('|')[2]
      })
    }
    this.sortedData.data = this.ELEMENT_DATA;
    */
  }
  onAddNewRow() {
    this.ELEMENT_DATA.push({
      id: this.ELEMENT_DATA.length + 1,
      fminute: '',
      tminute: '',
      cappingvalue: ''
    });
    this.sortedData.data = this.ELEMENT_DATA;
    this.onTimeCappingChanges.emit();
  }
  onRemoveRow(id: any) {
    this.ELEMENT_DATA = this.ELEMENT_DATA.filter(w => { return w.id != id });
    this.sortedData.data = this.ELEMENT_DATA;
    this.onTimeCappingChanges.emit();
  }
  onSave() {
    if (this.isValidData) {
      console.log(this.ELEMENT_DATA.map(w => { return w.fminute + '|' + w.tminute + '|' + w.cappingvalue }).join('||'));
    }
  }

  public digitsOnly = /^[0-9]*$/;
  allow_DigitsOnly(event: any, allowNagetive: boolean=false) {
    const inputChar = String.fromCharCode(event.charCode);

    if (allowNagetive && inputChar == '-' && event.target.value.includes("-")) {
      event.preventDefault();
    }
    else if (!this.digitsOnly.test(inputChar) && inputChar!="-") {
      event.preventDefault();
    }
  }
  public amuontOnly = /^[0-9]*$/;
  allow_amuont(event: any) {
    const inputChar = String.fromCharCode(event.charCode);
    if (inputChar == "-" && event?.target?.value.indexOf('-') > -1) {
      event.preventDefault();
    }
    else if (inputChar == "." && event?.target?.value.indexOf('.') > -1) {
      event.preventDefault();
    }
    else if (inputChar == "-" || inputChar == ".") {
    }
    else if (!this.digitsOnly.test(inputChar)) {
      event.preventDefault();
    }
  }
  castToFloat(event: any, element: any, control: string, type: string, maxvalue = 100) {
    let orgValue = event?.target?.value ?? ''
    let val = parseFloat(orgValue);
    if (orgValue) {
      if (type == 'int') {
        val = parseInt(orgValue);
      }
      if (isNaN(val)) {
        element[control] = "";
      }
      else if (val > maxvalue) {
        element[control] = "";
      }
      else {
        element[control] = val;
      }
    }
    else {
      element[control] = '';
    }
  }
  isValid(element: any, control: string) {
    return (element[control] ?? "") != "" || (element[control] ?? "") == "0";
  }

}
