<div class="body-wrapper">
  <mat-card class="main-card">
    <mat-card-content>
      <mat-card-title>
        CPT Details
        <button *ngIf="!showEdit && hascptdetails_Add" mat-mini-fab color="primary" style="float:right" (click)="onAddCptDetails()">
          <mat-icon>add</mat-icon>
        </button>

        <div class="col-lg-1" style="cursor: pointer; display: flow-root; float: right; height: fit-content; width: fit-content;" *ngIf="!showEdit && hascptdetails_Add">
          <input #f_input multiple type="file" id="upload" class="account-file-input" hidden="" accept=".csv,.xls,.xlsx" (change)="UploadFile($event)">
          <!--<button mat-flat-button style="margin-right: 10px; margin-bottom: 10px;" (click)="f_input.click()" color="primary">Upload</button>-->
          <p mat-mini-fab class="material-icons material-icon-preview" (click)="f_input.click()" color="primary" style="background: var(--background-color) !important;background-color: var(--background-color) !important; border-color: var(--background-color) !important; color: #fff; border-radius: 20px; font-size: 1.54em; margin-bottom: 0px; margin-right: 9px; padding: 3px; font-size: 32px; margin-top: 2px;">file_upload</p>
        </div>

        <div class="form-group col-lg-2" style="float: right; margin-right: 20px;" *ngIf="!showEdit">
          <input matInput class="form-control" (keyup)="applyFilter($event)" placeholder="Search" style="margin-left: -28px; font-size: small;" [value]="filtervalue">
        </div>


      </mat-card-title>
      <mat-card-subtitle> Home &gt; CPT Details</mat-card-subtitle>
      <mat-divider></mat-divider>
      <app-cpt-details-list [cptdetailsList]="cptdetailsList" *ngIf="!showEdit && hascptdetails_View" [filtervalue]="filtervalue" (onEditCptDetails)="onEditCptDetails($event)" (onDelete)="doDelete($event)" (onShowDetails)="onShowDetails($event)"></app-cpt-details-list>
      <section class="edit-container" *ngIf="showEdit && (hascptdetails_Add || hascptdetails_Edit)">
        <app-cpt-details-edit [lookupData]="lookupData" [isLoading]="isLoading" [cptdetails]="cptdetails" (onCancelEdit)="onCancelEdit($event)" (onShowLoading)="onShowLoading($event)" (onUpdateEdit)="doUpdateEdit($event)"></app-cpt-details-edit>
      </section>
    </mat-card-content>
  </mat-card>
</div>
<app-data-loader [isLoading]="isLoading"></app-data-loader>

